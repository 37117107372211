.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-dragging {
  cursor: grabbing;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.card {
  cursor: move;
}

.fade-in {
  animation: fadeIn 1s;
}

.fade-out {
  animation: fadeOut 1s;
}

.fade-in-out {
  animation: fadeInOut 6s infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

.slide-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0;
  transition: opacity 1s;
  pointer-events: none;
}

.slide-container.opacity-0::before {
  opacity: 1;
}

.slide-container img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slide-content {
  white-space: pre-wrap;
  width: 540px;
  height: 960px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Add this line */
  text-align: center;
  font-size: 1.6rem;
  padding: 1.5rem;
  box-sizing: border-box;
}

.slide-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0.5rem 0.5rem; /* Increase padding-top and padding-bottom */
  font-size: 0.5rem;
  color: white;
}
html,
body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1; /* This ensures the main content fills available space */
}

footer {
  flex-shrink: 0; /* Prevents footer from overlapping content */
}

/* Add this to your global stylesheet or a CSS module imported into PreviewGuide */
/* Hides scrollbar for WebKit browsers */
.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Hides scrollbar for Firefox */
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
}

/* If needed, ensure overflow auto for scrolling while hiding scrollbar */
.hide-scrollbar {
  overflow: auto;
}
